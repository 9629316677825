/* Container for the entire About Me section */
.about-me-container {
    padding: 40px;
    max-width: 1000px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #555;
  }
  
  /* Header section for the title and subtitle */
  .about-me-header {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .about-me-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .about-me-header p {
    font-size: 1.2rem;
    color: #999;
  }
  
  /* Card section containing the image and profile information */
  .about-me-card {
    display: flex;
    gap: 20px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Styling for the profile image */
  .about-me-image img {
    width: 250px;
    height: 250px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  /* Profile Info Styling */
  .about-me-info {
    flex: 1;
  }
  
  .about-me-info h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .about-me-info h4 {
    font-size: 1.2rem;
    color: #888;
    margin-bottom: 20px;
  }
  
  .about-me-info p {
    font-size: 1rem;
    color: #333;
    margin-bottom: 15px;
  }
  